

  /* You can add global styles to this file, and also import other style files */

 /* Daily Check-in start*/ 
* {
      
    box-sizing:border-box;
    margin:0;
    padding:0;}
    
    ul, ul li {list-style-type:none;}
    
    :root { font-size: 16px;}
    
    html { position: relative;  }
    
    body {
    font-size:14px;  
    background-color:#ffffff; 
    font-family:arial;} 

    textarea{
      font-family:arial;
    }
  
    .nopadding{
      padding: 0 !important;
    }
    
    a { transition: 0.3s;}
    
    a, a:hover { text-decoration:none;}
    
    .header {
        width:100%;
        height:165px;
        background-image: url("Header_Banner.png");
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    .header h4 {
        position: absolute;
        right: 30px;
        top: 70px;
        color: white;
        text-transform: uppercase;
        font-size:28px;
    }


    /*
    .abc {
        background-color: red;
      }
    */
    .container { padding: 0 25px; }
    
    .topsection  {
    min-height:100px;	
    border-bottom:solid 1px #cccccc; 
    padding: 15px 0 0 0;	}
    
    .topsection .form-group {
    width:15%;
    float:left; 
    text-align: left;
    margin-bottom: 15px;
    }
    
    .topsection .form-group label{
    color:#666666;
    font-weight:600;
    display:block;
    margin-bottom:10px; }
    
    .topsection  .form-group p { color:#666666;	padding: 0 0 0 18px; }
    
    .topsection .form-group select {
    width:100%;	
    padding: 5px; }
    
    
    .middle-section { padding:15px 35px; 	}
    
    .col-50 {
    width:50%;	
    float:left;	
    text-align: left;
    }
    
    .gray-bg {
    background-color:#eee;
    padding:10px; }
    
    .w-250 { width:250px; }
    
    .w-300 { width:300px; }
    
    .radio-wrapper  {  margin-bottom:10px; text-align: left; }

    .radio-wrapper label {
        margin-left: 11px;
    }
    
    .middle-section .inner-container h4 { 
    margin:0 0 5px 0;
    text-align: left;
    color:#666666;	}
    
    
    .table-top-menu  {
    height:35px;
    box-shadow: 1px 1px 2px #acabab;
    padding:10px 15px;
    margin-bottom: 10px; 
    text-align: left; }
    
    
    .table-top-menu button {
    border: none;
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    cursor:pointer;
    font-size: 14px;
    color: #666666;
    font-weight: bold;
     }
    
    .middle-section .inner-container table { width:100% }
    
    .middle-section .inner-container table tr th {
    padding: 5px;
    color:#666666; }
    
    .middle-section .inner-container table tr td, .middle-section .inner-container table tr th  {
    /*width:50%;*/
    text-align:left; }
    
    .middle-section .inner-container table tr td {
    border-bottom:solid 1px #ddd;
    padding: 10px 5px;}
    
    
    .comments-wrapper label {
    color:#666666;
    font-weight:600;
    display:block;
    margin-bottom:10px; }
    
    .comments-wrapper textarea {
    width:100%;
    background-color:#eee;
    border:none;
    resize:none; }
    
    .mr-tp-15 { margin-top:15px;}
    
    .btn-primary {
    background-color: #64619f;
    color: #fff;
    border: none;
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    min-width: 120px;
    font-weight: 600;
    position: absolute;
    bottom: 20px;
    left: 50%; 
    cursor:pointer;  }
    
    .btn-primary:hover {opacity:.8;}
    
    .btn-wrapper {
    text-align: center;
    height: 182px;
    position: relative; }
    
    
    .clear { clear:both;}

    .logowrapper {
        width: 48px;
        height: 48px;
    }

    .logowrapper img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
 /* Daily check-in End */   

 /* Manage Teams Schedule CSS Start */

.manage-teams-wrapper { padding: 15px 0px; }

.d-table-wrapper .d-table-header{
background-color: #f2f3f2;
height: 35px;
color: #000;
font-size: 15px;
font-weight: bold;
padding: 10px 15px; 
text-align: left;
}

.manage-teams-wrapper .d-table-body table { width:100%; }

.user-photo-wrapper {
width:40px;
height:40px; }

.user-photo-wrapper img {
width:100%;
height:100%;
object-fit:cover;
border-radius:100px;	 }

.user-photo-wrapper, .user-details  {
display: inline-block;
vertical-align: middle; }

.user-details {
width: calc(100% - 55px);
margin-left: 5px; }

.user-details .user-name {
font-weight: normal;
font-size: 16px; }

.user-details .department-name {
font-size: 13px;
font-weight: normal; }

.manage-teams-wrapper .d-table-body table  thead tr th {
text-align:left;
padding: 15px 10px;
color: #666666;
white-space: nowrap; }

.manage-teams-wrapper .d-table-wrapper .d-table-body table tr td select {
width: 100%;
padding: 0 5px;
height: 28px;	 }


.manage-teams-wrapper .d-table-wrapper .d-table-body table textarea {
  width: 100%;
  resize: none;
  background-color: #fff;
  font-family: arial;
  padding: 5px 5px;
  height: auto;
} 

.manage-teams-wrapper .d-table-body table  tbody tr td { padding: 5px 10px; text-align: left; color: #000000;
  font-size: 13px;
  vertical-align: top;
}


.manage-teams-wrapper .d-table-wrapper .d-table-body { padding: 0 10px;}

.manage-teams-wrapper .d-table-wrapper .d-table-body table tr td a  { color:#1481f2; }

.manage-teams-wrapper .d-table-wrapper .d-table-body table tr td a:hover  { color:#000;  text-decoration:underline;}

.text-right  { text-align:right;}

.manage-teams-wrapper .d-table-wrapper .d-table-bottom { padding:15px 0 5px 0;}

.manage-teams-wrapper .d-table-wrapper .d-table-bottom .btn {
min-width:100px;
height:36px;
background-color:transparent;
padding:4px;
border:none;
font-weight:600;
cursor:pointer;
margin-right:5px; }

.manage-teams-wrapper .d-table-wrapper .d-table-bottom .btn:last-child { margin-right:0;}


.manage-teams-wrapper .d-table-wrapper .d-table-bottom .primary-btn {
background-color:#6264a7;
color:#fff; }

.manage-teams-wrapper .d-table-wrapper .d-table-bottom .default-btn {
background-color:#fff;
color:#000;
border:solid 1px #b6b6b6; }

.manage-teams-wrapper .d-table-wrapper .d-table-bottom .btn:hover { opacity:.8;}

/* Manage Teams Schedule CSS End */

/* Attendance Palnner CSS Start */
.table-buttons-wrapper{
    background-color: #f7f7f7;
    height: 40px;
    box-shadow: 1px 1px 2px #acabab;
    padding: 13px 15px;
    margin-bottom: 10px;
    margin-top: 5px; 
    text-align: left;
}

.table-buttons-wrapper button  {
border: none;
display: inline-block;
vertical-align: top;
margin-right: 20px;
cursor: pointer;
background-color:transparent;
font-size: 14px;
color: #666666;
font-weight: bold; }
	
.attendance-planner-wrapper .d-table-wrapper .d-table-body {
background-color:#f7f7f7; }

/* Attendance Palnner CSS End */


/* Manage Division  CSS Start */
.user-wrapper {
display: inline-block;
vertical-align: top; 
min-width:160px;
width: -webkit-fill-available;
}

.psave{
    text-align:center;
    color: darkgreen;
    font-weight: bold;
}

.hrhidden { display:none; }

.phrhidden { color: red; }

/* .modal {
    display: none;
    position: fixed;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.25);
  }

.modal_content {
    background-color: white;
    position: absolute;
  } */
  
/* Manage Division  CSS End */

.table-responsive {
overflow-x:auto;
-webkit-overflow-scrolling: touch; }

.table-responsive table .form-control {
height: 28px;
width: 99%;
padding: 0 5px; }

.label-icon {
width: 15px;
height: 15px;
display: inline-block;
vertical-align: top;
margin-right: 3px;}

/* Modal Popp CSS STart */

.modal {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    display: block; 
  }

  .hide {
    display: none;
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
  /* The Close Button */
.close {
color: #aaaaaa;
float: right;
font-size: 28px;
font-weight: bold;
position: relative;
top: -10px; }
  
.close:hover,
.close:focus {
color: #000;
text-decoration: none;
cursor: pointer;}

.modal-header h4 {
font-size: 18px;
text-align: left;
font-weight: 600;
margin-bottom: 15px;
color: #262424;}

 
.modal .modal-body table { width: 100%; } 

.modal .modal-body table thead th  { 
text-align:left;
padding: 5px;
color: #666666; } 

.modal .modal-body table tbody tr td {
border-bottom: solid 1px #ddd;
padding: 10px 5px; }

.modal .modal-large { width: 50%;}


.modal { text-align: center; }

.modal::before {
content: "";      
display: inline-block;
height: 100%;    
margin-right: -4px;
vertical-align: middle; }

.modal-content { 
display: inline-block;  
text-align: left;   
vertical-align: middle; }

.modal-body {
max-height: 250px;
overflow: auto;}

/* Modal Popp CSS End */


input[type="date" i] {
background-color: #fff;
height: 28px;
width: 90%;
padding: 0 5px;
border: solid 1px #000;
color: #000;
font-family: arial; }

input[type="date" i]:read-only {
background-color: #cecece;
color: #6c6c6c; }

.show-bt {
min-width: 100px;
height: 30px;
padding: 4px;
border: none;
font-weight: 600;
cursor: pointer;
background-color: #6264a7;
color: #fff;}

.show-bt:hover { opacity: .8; }

.info-message {
display: inline-block;
vertical-align: middle;
color: #0c5460;
font-weight: bold;}

span.info-message.block {
  display: block;
  text-align: left;
  margin-bottom: 5px;
}

.info-message i {margin-right: 5px;}

.fl {float:left;}

.fr {float:right;}

.authorization-wrapper p{
font-size: 16px;
margin: 20px; 
}
.authorization-wrapper i {
     margin-right: 5px;
}

.disabled,
.table-top-menu button:disabled,
.table-buttons-wrapper button:disabled,
.manage-teams-wrapper .d-table-wrapper .d-table-bottom .btn:disabled {
pointer-events: none;
cursor: default;
opacity: 0.3; }

.modal .modal-footer .d-table-bottom.fr { padding: 0 !important; }

.modal .modal-footer .info-message.fl{ padding: 10px 0; }

.modal-footer { margin: 10px 0 0 0;}

.manage-teams-wrapper .d-table-wrapper .d-table-bottom .info-message { padding: 10px 0; }

@media screen and  (max-width: 560px) {

    .header { height:70px !important; }

    .header h4 { padding: 5px 20px !important; }

    .attendance-planner-wrapper .d-table-bottom .info-message,
    .manage-supervisor-wrapper .d-table-bottom .info-message{ margin-bottom:5px;}

 }

@media screen  and (max-width: 991px) {
    .header {
    height: 125px;
    margin-bottom: 30px;}
    
    .header h4 {
    top: inherit;
    bottom: -30px;
    color: #fff;
    left: 0;
    margin: 0;
    left: 0 !important;
    width: 100%;
    background: #333333;
    font-size: 17px;
    text-align: left;
    padding: 5px 40px; }

    .modal .modal-large { width:90%;}

  }


@media screen and (min-width: 992px) and (max-width: 1150px) {
    .header {
        background-color: #1481f2;
        height: 125px;}
    
    .header h4 {top: 50px;}

  }

  @media  (max-width:575px) {

    .topsection .form-group {width: 50%;}

   }

  @media (min-width:576px) and (max-width: 991px) {

    .topsection .form-group {width: 25%;}

   }

   .inputtextbox{
      background: transparent;
      border: none;
      outline-width: 0;
   }

   .timefield{
      width: 50px
   }


   .text-danger{
     color: red;
   }

   .w-70{
     width: 70% !important;
   }

   .text-danger-task{
    color: red;
    font-size: 12px;
  }

  .blink {
    animation: blinker 0.6s linear infinite;
    color: #0c5460;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
 
  .analytics_align {
    text-align: center;
    margin: 0 auto;
    width: 200px;
  }

  .dailyPA{
    text-align: left !important;
    color: #0c5460;
    font-weight: bold;
    margin-bottom: 5px;
    margin-left: 15px;
  }

  .MuiAppBar-root .MuiTab-wrapper{
    white-space: nowrap;
    overflow: hidden
}
     
   
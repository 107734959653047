.daily-checkin-wrapper {
    /* background-color:red; */
}

.daily-checkin-wrapper .check-box-wrapper {
display: block;
margin-bottom: 10px; }

.daily-checkin-wrapper .check-box-wrapper label {
display: inline-block;
vertical-align: top; 
margin: 0 0px 0 7px; }

.daily-checkin-wrapper .check-box-wrapper:last-child { margin-bottom:0;}


@media (max-width:767px)   { 

    .daily-checkin-wrapper .middle-section {padding: 15px 0px;}

   .daily-checkin-wrapper .middle-section .col-50 {
    width:100%;
    margin-bottom: 15px; }

    .daily-checkin-wrapper .middle-section .col-50 .w-250 { width:100%; }
    
    .daily-checkin-wrapper .middle-section .col-50 .w-300 { width:100%; }

    .daily-checkin-wrapper .middle-section .btn-wrapper {
    height: auto;
    text-align: left; }

    .daily-checkin-wrapper .middle-section .btn-primary {
    position: inherit;
    bottom: 0;
    left: 0; }

    .daily-checkin-wrapper .middle-section .mr-tp-15  { margin-top: 5px;}
 
}